@import './theme';
@import './type';
@import './grid';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #f3f8fb;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0);
  -ms-scrollbar-track-color: black;
  -ms-scrollbar-face-color: rgb(153, 153, 153);
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.25rem rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    background-color: transparent;
  }

  div::-webkit-scrollbar {
    width: 0.5rem;
    background-color: transparent;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
  @include md-down {
    font-size: 14px;
  }
  @include sm-down {
    font-size: 12px;
  }
}

#root {
  height: 100%;
  width: 100%;
}

.container {
  max-width: $max-width;
  margin: 0 auto;
  padding-top: $base-spacing * 0.5;
  padding-left: $base-spacing * 0.5;
  padding-right: $base-spacing * 0.5;
  padding-bottom: $base-spacing * 0.5;
}

.relative {
  position: relative;
}

.flip-h {
  transform: rotate(180deg);
}

.rotate-left {
  transform: rotate(90deg);
}

.card {
  text-decoration: none;
  background-color: white;
  border-radius: $border-radius * 2;
  margin: 0 auto;
  border: 1px solid #cee3ee;
  &._modal,
  &._big {
    width: 37.5rem;
    max-width: 100%;
    min-height: 10rem;
    max-height: 100vh;
    margin: 0;
    padding-left: 1.5 * $base-spacing;
    padding-right: 1.5 * $base-spacing;
    padding-top: 2 * $base-spacing;
    padding-bottom: 2.5 * $base-spacing;
  }
  &._modal {
    max-height: 100vh;
  }
  &._center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &._small {
    max-width: 450px;
  }
  & > .divider {
    margin-top: $base-spacing * 1.5;
    margin-bottom: $base-spacing * 1.5;
  }
}

.divider {
  border-top: 1px solid #aebbc2;
  height: 0px;
}

.no-pad {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.pad {
  padding: $base-spacing * 0.5;
}
.pad2 {
  padding: $base-spacing;
}
.pad-h {
  padding-left: $base-spacing * 0.5;
  padding-right: $base-spacing * 0.5;
}
.pad-v {
  padding-top: $base-spacing * 0.5;
  padding-bottom: $base-spacing * 0.5;
}
.pad-top {
  padding-top: $base-spacing * 0.5 !important;
}
.pad-bottom {
  padding-bottom: $base-spacing * 0.5 !important;
}
.pad-left {
  padding-left: $base-spacing * 0.5 !important;
}
.pad-right {
  padding-right: $base-spacing * 0.5 !important;
}

.margin-h {
  margin-left: $base-spacing * 0.5;
  margin-right: $base-spacing * 0.5;
}
.margin-v {
  margin-top: $base-spacing * 0.5;
  margin-bottom: $base-spacing * 0.5;
}
.margin-top {
  margin-top: $base-spacing * 0.5 !important;
}
.margin-bottom {
  margin-bottom: $base-spacing * 0.5 !important;
}
.margin-left {
  margin-left: $base-spacing * 0.5 !important;
}
.margin-right {
  margin-right: $base-spacing * 0.5 !important;
}
.no-margin {
  margin: 0 !important;
}
.margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.category-pill {
  @extend .caption;
  background-color: rgba(#8e86af, 0.2);
  border-radius: 1rem;
  padding: 0 0.5rem;
  margin: 0;
  &.airport {
    background-color: rgba($airport, 0.2);
  }
  &.business {
    background-color: rgba($business, 0.2);
  }
  &.bar {
    background-color: rgba($bar, 0.2);
  }
  &.gas_station {
    background-color: rgba($gas_station, 0.2);
  }
  &.restaurant {
    background-color: rgba($restaurant, 0.2);
  }
  &.shopping {
    background-color: rgba($shopping, 0.2);
  }
  &.things_to_do {
    background-color: rgba($things_to_do, 0.2);
  }
  &.cafe {
    background-color: rgba($cafe, 0.2);
  }
  &.relaxation {
    background-color: rgba($relaxation, 0.2);
  }
  &.lodging {
    background-color: rgba($lodging, 0.2);
  }
}
