.grid {
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  @include md-down {
    &.md-stack {
      display: block;
      & > .col {
        display: block;
        width: 100%;
      }
    }
  }
  @include sm-down {
    &.sm-stack {
      display: block;
      & > .col {
        display: block;
        width: 100%;
      }
    }
  }
}
.col {
  flex: 1 1 0px;
  min-height: 0px;
  min-width: 0px;
  padding: $base-spacing * 0.5;
}
.shrink {
  flex: 0 0 auto;
  flex-basis: auto;
  width: auto;
}
.full {
  flex: 0 0 auto;
  width: 100%;
}
.half {
  flex: 0 0 auto;
  width: 50%;
}
.third {
  flex: 0 0 auto;
  width: 33.3333%;
}
.fourth {
  flex: 0 0 auto;
  width: 25%;
}
@include sm-up {
  &.sm {
    &-full {
      flex: 0 0 auto;
      width: 100%;
    }
    &-half {
      flex: 0 0 auto;
      width: 50%;
    }
    &-third {
      flex: 0 0 auto;
      width: 33.3333%;
    }
    &-fourth {
      flex: 0 0 auto;
      width: 25%;
    }
  }
}
@include md-up {
  &.md {
    &-shrink {
      flex: 0 0 auto;
      flex-basis: auto;
      width: auto;
    }
    &-full {
      flex: 0 0 auto;
      width: 100%;
    }
    &-half {
      flex: 0 0 auto;
      width: 50%;
    }
    &-third {
      flex: 0 0 auto;
      width: 33.3333%;
    }
    &-fourth {
      flex: 0 0 auto;
      width: 25%;
    }
  }
}
@include lg-up {
  &.lg {
    &-shrink {
      flex: 0 0 auto;
      flex-basis: auto;
      width: auto;
    }
    &-full {
      flex: 0 0 auto;
      width: 100%;
    }
    &-half {
      flex: 0 0 auto;
      width: 50%;
    }
    &-third {
      flex: 0 0 auto;
      width: 33.3333%;
    }
    &-fourth {
      flex: 0 0 auto;
      width: 25%;
    }
  }
}
.flex {
  display: flex;
}
.direction-column {
  flex-direction: column;
}
.justify {
  &-stretch {
    justify-content: stretch;
  }
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-space-around {
    justify-content: space-around;
  }
}
.align {
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
  &-center {
    align-items: center;
  }
  &-stretch {
    align-items: stretch;
  }
  &-baseline {
    align-items: baseline;
  }
}
