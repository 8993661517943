@import '../../assets/styles/theme';

.container {
  background-color: $brand2;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  align-content: center;
  justify-content: center;
  color: white;
  font-size: 150%;
  text-align: center;
  p {
    color: white;
    font-size: inherit;
  }
}

.logo {
  margin: 1rem auto;
  width: 500px;
  max-width: 100%;
}
