.threshold {
  position: relative;
  width: 100%;
  height: 0;
  max-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: start;
  padding: 0.25rem;
  padding-top: 56.25%;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 110%;
  border: none;
  outline: none;
  color: white;
  transition: opacity 0.25s ease;
  border-radius: 0.25rem;
  &:hover {
    opacity: 0.72;
  }
  &.large {
    height: 14rem;
    font-size: 0.8rem;
  }
  .thresholdTitle {
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;
  }
}
