.header {
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #182931;
}

.content {
  padding: 0.5rem;
}

.backButton {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 3rem);
}
