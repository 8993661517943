.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.stepName {
  position: absolute;
  z-index: 2;
  bottom: 3.5rem;
  right: 50%;
  transform: translateX(50%);
  padding: 0.5rem 0.75rem;
  border-radius: 2rem;
  color: white;
  font-size: 0.875rem;
  background-color: rgba(0, 0, 0, 0.6);
  @media (min-width: 700px) {
    bottom: 0.5rem;
  }
}
