// Colors
$brand1: #0b74a8;
$brand2: #08405c;
$brand3: #12bbee;
$gray3: #74848c;
$gray4: #5b6a72;
$gray6: #182931;
$white: white;
$bg-light: #f3f8fb;
$bg-gray: #f1f3f5;
$font-primary: #33434c;
$accent1: #cee3ee;
// Category Colors
$airport: #8a96a4;
$business: #a5a48f;
$bar: #8e86af;
$gas_station: #86838a;
$restaurant: #e17c68;
$shopping: #53a9b6;
$things_to_do: #7fc18e;
$cafe: #f3ae46;
$relaxation: #d7668f;
$lodging: #5d85c0;

$bg-primary: $brand2;
$bg-white: $white;
$heading-primary: $brand2;
$base-spacing: 1rem;
$btn-primary: $brand1;
$btn-primary-txt: $white;
$border-radius: 4px;
$tablet-landscape-width: 680px;
$tablet-portrait-width: 680px;
$desktop-width: 960px;
$max-width: 1440px;
$button-cursor: pointer;

@mixin lg-up {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: #{$tablet-landscape-width}) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: #{$tablet-portrait-width}) {
    @content;
  }
}

@mixin sm-down {
  @media (max-width: #{$tablet-landscape-width - 1px}) {
    @content;
  }
}

@mixin md-down {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
