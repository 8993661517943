.font {
  font-family: Source Sans Pro;
  font-style: normal;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.125rem 0.25rem;
}

.heading {
  @extend .font;
  color: $heading-primary;
  font-weight: 600;
  padding: 0.5rem;
}

.display1 {
  @extend .font;
  color: $heading-primary;
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 4.8125rem;
}
.display2 {
  @extend .font;
  color: $heading-primary;
  font-weight: normal;
  font-size: 3rem;
  line-height: 4.125rem;
}
.h1 {
  @extend .heading;
  font-size: 2.5rem;
  line-height: 2.875rem;
}
.h2 {
  @extend .heading;
  font-size: 2rem;
  line-height: normal;
}
.h3 {
  @extend .heading;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
}
.h4 {
  @extend .heading;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.sub-h1 {
  @extend .font;
  font-weight: normal;
  color: $gray4;
  font-size: 1.25rem;
  line-height: 2rem;
}
.sub-h2 {
  @extend .font;
  font-weight: normal;
  color: $gray4;
  font-size: 1.125rem;
  line-height: 1.625rem;
}
p,
.body {
  @extend .font;
  color: $font-primary;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5625rem;
  &._dark {
    color: white;
    a {
      color: $white;
      text-decoration: underline;
    }
  }
}
.caption {
  @extend .font;
  color: $font-primary;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4375rem;
}

strong,
b,
.bold {
  font-weight: 600;
}

a {
  color: $brand1;
  text-decoration: none;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.5;
  }
}

.capital {
  text-transform: capitalize;
}

.txt-gray {
  color: $gray4;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-brand1 {
  color: $brand1 !important;
}
