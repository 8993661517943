$titleHeight: 3.5rem;

.leadGenWrapper {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 3;
  background-color: rgba(#000, 0.88);
  min-width: 33%;
  width: 350px;
  max-width: calc(100% - 1rem);
  border-radius: 0.5rem;
  overflow: hidden;
  box-sizing: border-box;
  height: $titleHeight;
  max-height: $titleHeight;
  transition: all 0.5s ease;
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  @media (min-width: 725px) {
    top: 0.5rem;
    right: 0.5rem;
    left: auto;
  }
  @media (min-width: 1400px) {
    min-width: 0;
    width: 440px;
  }
  * {
    box-sizing: border-box;
  }
}

.container {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 3;
  background-color: rgba(#000, 0.88);
  min-width: 33%;
  width: 350px;
  max-width: calc(100% - 1rem);
  border-radius: 0.5rem;
  overflow: hidden;
  box-sizing: border-box;
  height: $titleHeight;
  max-height: $titleHeight;
  transition: all 0.5s ease;
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  margin-top: 4rem;
  @media (min-width: 725px) {
    margin-top: 0;
  }
  @media (min-width: 1400px) {
    min-width: 0;
    width: 440px;
  }
  * {
    box-sizing: border-box;
  }
  &.noTitle {
    min-width: 0;
    width: 2.75rem;
    transition: all 0.125s ease;
    .titleContainer {
      justify-content: center;
    }
    .multiStep {
      margin-left: 0;
    }
  }
  button {
    cursor: pointer;
  }
  &.active {
    height: auto;
    max-height: calc(100% - 3rem);
    overflow: auto;
    padding-bottom: 0.75rem;
    .multiStep {
      background-color: #555;
    }
  }
  &:not(.active).hasCTA {
    height: $titleHeight + 3rem;
    max-height: $titleHeight + 3rem;
    .titleContainer {
      height: $titleHeight + 3rem;
      align-content: space-between;
      padding: 0.5rem;
    }
    .ctaButton {
      align-self: flex-start;
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
      flex: none;
    }
  }
}

.titleContainer {
  height: $titleHeight;
  display: flex;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: space-between;
  border-radius: 0.5rem;
  flex-wrap: wrap;
}
.leadGenContainer {
  height: $titleHeight;
  display: flex;
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: space-between;
  border-radius: 0.5rem;
  flex-wrap: wrap;
}
.title {
  flex: 1;
  padding: 0.25rem;
  overflow: hidden;
  .locationName {
    font-size: 1rem;
    font-weight: 600;
  }
  .activeStepName {
    font-size: 0.75rem;
  }
}
.shareButton {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  margin-bottom: 0.5rem;
  svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
  span {
    margin-left: 0.25rem;
    color: white;
    font-size: 0.85rem;
    margin-top: 2px;
  }
}
.logo {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: white;
}
.logo2 {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: transparent;
  margin-left: 1rem;
  margin-bottom: -2rem;
  z-index: 4;
  border: 2px solid black;
  transform: translateY(-50%);
  background-color: white;
}
.multiStep {
  padding: 0;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  background-color: transparent;
  transition: background-color 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.subTitle {
  font-weight: 400;
  padding: 0 0.25rem;
  margin: 1rem 0 0;
  font-size: 1.125rem;
}
.contentContainer {
  // padding: 0.5rem;
  margin-top: 0.25rem;
  overflow-x: hidden;
}
.thresholdContainer {
  // padding: .25rem;
}
.thresholdList {
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.threshold {
  display: flex;
  width: 50%;
  padding: 0.25rem;
  box-sizing: border-box;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.openPanel {
  .locationName {
    display: flex;
    width: calc(100% - 70px);
    align-items: center;
    padding: 0.5rem 1rem;
    padding-right: 1.5rem;
    margin: -0.5rem;
    margin-bottom: 0;
    // background-color: black;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .thumbnail {
    position: relative;
    height: 0;
    padding-top: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .details {
    position: relative;
    padding: 0.5rem;
    font-size: 0.88rem;
    & > div {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      overflow: hidden;
      svg {
        margin-right: 0.5rem;
        width: 1rem;
        align-self: flex-start;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  border: none;
  background-color: rgba(#000, 0.5);
  padding: 0.5rem;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  height: 2rem;
  width: 2rem;
  svg {
    width: 0.88rem;
    height: 0.88rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hours {
  .info {
    width: 100%;
  }
  ul {
    padding: 0;
    margin: 0.5rem 0 0;
    display: block;
  }
  li {
    margin: 0;
    padding: 0.5rem 0.5rem;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(white, 0.5);
  }
}

.locationName {
  font-size: 1.25rem;
  line-height: normal;
  font-weight: normal;
}

.locationMap {
  height: 12rem;
  width: calc(100% + 1rem);
  background-position: center;
  background-size: cover;
  margin: 2rem -0.5rem 0;
}

.hoursTitle {
  display: flex;
  justify-content: space-between;
  padding: 0.125rem;
  label {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
}

.toggleHours {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  & > svg {
    margin-right: 0 !important;
    transform: rotate(180deg);
  }
  &.open {
    & > svg {
      transform: rotate(0deg);
    }
  }
}

.moreThresholds {
  width: 100%;
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  font-size: 1rem;
  display: flex;
  align-content: flex-end;
  justify-content: center;
  transition: opacity 0.25s ease;
  text-decoration: underline;
  margin-top: 0.5rem;
  &:hover {
    opacity: 0.8;
  }
}

.ctaButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b74a8;
  padding: 0.75rem;
  border-radius: 0.25rem;
  color: white;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}

.copyButton {
  position: absolute;
  right: 1.5rem;
  button,
  button:hover,
  button:focus,
  button:active {
    background-color: #0b74a8;
    border-color: #0b74a8;
  }
}
